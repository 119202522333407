<template>
    <div>
        <CompHeader title="我的藏品" />
    </div>
</template>

<script>
import CompHeader from "@@/common/header.vue"
export default {
    components: {
        CompHeader
    }
}
</script>
